.link {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 222px;
    height: 62px;
    background-color: #f1f1f1;
    text-decoration: none;
    color: #747474;
    font-size: 20px;
    text-align: center;
    border: 1px solid #c9c9c9;
}

.under_text {
    font-size:12px;
    color: #747474;
}