.heading {
    margin: 0px 15px 0 15px;
    display: flex;
    padding-top: 15px;
}

.heading_color {
    width: 49px;
    height: 40px;
}

.heading_title {
    font-size: 18px;
    color: #6A6868;
    margin-left: 12px;
    margin-top: 10px;
}
