.links {
    display: flex;
    margin: 15px 15px 15px 15px;
    width: 1024px;
    flex-wrap: wrap;
    gap: 21px 24px;
}

.cs_card{
    padding: 20px 20px;
    margin-bottom: 20px;
    background-color: #ffffff;
    box-shadow: 4px 4px 10px #3c3b3b;
    border-radius: 7px;
}

.error_tag{
    text-align: center;
}

.error_content{
    color: red;
}
.job_time{
    max-width: 120px;
}
