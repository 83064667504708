
.body_flex{
    display: flex;
    justify-content: center;
    align-items: center;
}

.content_flex_column{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.content_align_center{
    align-items: center;
}

.content_flex_row{
    display: flex;
    justify-content: center;
    align-items: center;
}

.title{
    font-size: 20px;
    margin-top: calc(100vh/20);
}
.sub_title{
    font-size: 16px;
}

.row_gap{
    gap : 10px;
}

.but_gap{
    gap : 20px;
}

.mt70{
    margin-top: 70px;
}

.mt50{
    margin-top: 50px;
}

.mt40{
    margin-top: 40px;
}

.mt30{
    margin-top: 30px;
}

.mt20{
    margin-top: 20px;
}
.mt15{
    margin-top: 15px;
}
.mt10{
    margin-top: 10px;
}
.mt5{
    margin-top: 5px;
}
.useful_display{
    background-color: red; /* Green */
    border: none;
    color: white;
    padding: 1px 2px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 11px;
    margin: 2px 8px;
    cursor: pointer;
    vertical-align: text-bottom;
    border-radius: 2px;
}
.label_flex{
    display: flex;
    align-items: center;
    font-size: 14px;
}
.time_label{
    font-size: 16px;
    letter-spacing: 2px;
    margin-top: 5px;
}
.select{
    height: 35px;
    border: none;
    border-bottom: 1px solid #d9d9d9;
    font-size: 16px;
    width: 100%;
    padding: 0 3px;
    color: #333333;
    background-color: white;
}
.select:focus{
    outline: none;
}
.input{
    border: none;
    border-bottom: 1px solid #d9d9d9;
    height: 30px;
    width: 100%;
    font-size: 16px;
    color: #333333;

}
.input::placeholder{
    color: #d9d9d9;
    font-size: 15px;
}
.input:focus{
    outline: none;
}
.button{
    width: 120px;
    margin-top: 30px !important;
    height: 35px !important;
    font-size: 16px;
    line-height: 15px;
}

.card_head{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 40px;
    border: 1px solid #d9d9d9;
    font-size: 16px;
    font-weight: 600;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-top: 40px;
}

.card_body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 40px;
    border: 1px solid #d9d9d9;
    font-size: 16px;
    border-top: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.form_label{
    font-size: 15px;
    margin-bottom: 4px;
    color: #666666;
}