/*====================================================================================================
	作成日:2022/08/25		最終更新日:2022/08/26
	作成者:GFTD天神-徳渕	最終更新者:GFTD天神-徳渕
====================================================================================================*/

.headline_div {
    /* Layout Properties */
    width: 960px;
    height: 40px;
    margin: 26px 21px 0 65px;

    /* UI Properties */
    opacity: 1;
}

.headline_text {
    /* Layout Properties */
    width: 100px;
    height: 17px;
    padding: calc((40px - 17px) / 2);

    /* UI Properties */
    text-align: left;
    letter-spacing: 0px;
    color: #1F0707;
    opacity: 1;
}

.headline_border_bottom {
    /* Layout Properties */
    width: 1062.5px;
    height: 1px;

    /* UI Properties */
    border-bottom: 1px solid #1188E8;
    opacity: 1;
}