.header {
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: white;
}

.left_area {
    z-index: 1;
    width: 220px;
    background-color: white;
    display: flex;
    transition: all 0.5s;
}

.left_area_moved {
    width: 220px;
    background-color: white;
    transform: translateX(-190px);
    transition: all 0.5s;
}

.btn_trigger {
    position: relative;
    width: 26px;
    cursor: pointer;
    padding: 16px 0px;
    margin:16px 0px 16px 10px;
}

.btn_trigger span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 1.8px;
    background-color: #747474;
    border-radius: 3px;
}

.btn_trigger, .btn_trigger span {
    display: inline-block;
    transition: all .5s;
    box-sizing: border-box;
}

.btn_trigger span:nth-of-type(1) {
    top: 0;
  }
.btn_trigger span:nth-of-type(2) {
    top: 9px;
  }
.btn_trigger span:nth-of-type(3) {
    top: 18px;
}

.btn_trigger_clicked {
    position: relative;
    width: 26px;
    cursor: pointer;
    padding: 16px 0px;
    margin:16px 0px 16px 10px;
}

.btn_trigger_clicked span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 1.8px;
    background-color: #747474;
    border-radius: 3px;
}

.btn_trigger_clicked span:nth-of-type(1) {
    top: 0;
  }
.btn_trigger_clicked span:nth-of-type(2) {
    top: 9px;
  }
.btn_trigger_clicked span:nth-of-type(3) {
    top: 18px;
}

.btn_trigger_clicked span:nth-of-type(1) {
    transform: translateY(9px) rotate(-45deg);
    transition: all 0.5s;
}

.btn_trigger_clicked span:nth-of-type(2) {
    opacity: 0;
    transition: all 0.5s;
}

.btn_trigger_clicked span:nth-of-type(3) {
    transform: translateY(-9px) rotate(45deg);
    transition: all 0s;
}

.right_area {
    display:flex;
    margin-right: 40px;
    margin-left: 200px;
}

.link {
    width: 140px;
    height: 59px;
    text-align: center;
}

.top_text {
    font-size: 16px;
    color: #747474;
    padding-top: 15px;
}

.bottom_text {
    font-size: 12px;
    color: #1188E8;
    padding-bottom: 12px;
}

.user {
    width: 160px;
    height: 59px;
    margin-left: 32px;
    background-color: #1188E8;
    color: white;
}

.user_top_text {
    font-size: 13px;
    margin: 5px 0px 0px 11px;
}

.user_bottom_text {
    width:80px;
    font-size: 16px;
    margin: 0 auto;
}