/*====================================================================================================
	作成日:2022/08/26		最終更新日:2022/08/26
	作成者:GFTD天神-徳渕	最終更新者:GFTD天神-徳渕
====================================================================================================*/

.button_area{
    padding-top: 0em;
    padding-bottom: 2em;
}

.link {
    display: inline-block;
    text-decoration: none;

    /* UI Properties */
    background: #1188E8 0% 0% no-repeat padding-box;
    opacity: 1;

    /* UI Properties */
    letter-spacing: 0px;
    color: #FFFFFF;

    width: 132px;
    height: 28px;
    text-align: center;
}

.link:visited,
.link:hover {
    color: #FFFFFF;
}