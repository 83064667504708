/*====================================================================================================
	作成日:2022/08/25		最終更新日:2022/08/26
	作成者:GFTD天神-徳渕	最終更新者:GFTD天神-徳渕
====================================================================================================*/

.link {
    display: inline-block;
    text-decoration: none;
    
    /* Layout Properties */
    padding: 1em 4em 1em 4em;

    /* UI Properties */
    background: #1188E8 0% 0% no-repeat padding-box;
    border-radius: 40px;
    opacity: 1;

    /* UI Properties */
    color: var(--unnamed-color-ffffff);
    letter-spacing: 0px;
    color: #FFFFFF;
}

.link:visited,
.link:hover {
    color: #FFFFFF;
}