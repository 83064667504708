/*====================================================================================================
	作成日:2022/08/25		最終更新日:2022/08/26
	作成者:GFTD天神-徳渕	最終更新者:GFTD天神-徳渕
====================================================================================================*/

.font {
    /* UI Properties */
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-17) var(--unnamed-font-family--applesystemuifont);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-333333);
    letter-spacing: 0px;
    color: #333333;
}