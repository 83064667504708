.link {
    /* display: inline-block; */
    min-width: 20%;
    height: 62px;
    background-color: #f1f1f1;
    text-decoration: none;
    color: #747474;
    font-size: 20px;
    text-align: center;
    border: 1px solid #c9c9c9;
    text-decoration: none;
    color: #6A6868;
    padding: 0px 15px;

    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}

.under_text {
    font-size:12px;
    color: #747474;
}