/*====================================================================================================
	在庫移動処理 -> AdobeXD 23ページ
	作成日:2022/08/25		最終更新日:2022/08/26
	作成者:GFTD天神-徳渕	最終更新者:GFTD天神-徳渕
====================================================================================================*/

.flex {
    display: flex;
    flex-flow: row;

    /* UI Properties */
    opacity: 1;
}

.title {
    /* Layout Properties */
    width: 132px;
    height: 28px;

    text-align: center;

    /* UI Properties */
    background: var(--unnamed-color-dedbdb) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-d6d6d6);
    background: #DEDBDB 0% 0% no-repeat padding-box;
    border: 1px solid #D6D6D6;
    opacity: 1;
}

.input_text {
    /* Layout Properties */
    width: 198px;
    height: 28px;
}