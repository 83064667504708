.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #00000075;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
    z-index: 10;
}
.modal.show {
    opacity:1;
    pointer-events: visible;
}
.modal-content {
    width: 500px;
    background-color: #ffffff;
}
.modal-header, .modal-footer {
    padding: 10px;
}
.modal-title {
    margin: 0;
}
.modal-body {
    padding: 10px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}
.close-btn {
    float: right;
    margin: 2px 5px 10px 0px;
    width: 70px;
    height: 25px;
    background-color: #1188E8;
    color: #ffffff;
    border: none;
    box-shadow: 0px 3px 6px #00000029;
    font-size: 14px;
    cursor: pointer;
}






