
div.react-datepicker-wrapper {
    height: 30px;
    max-width: 196px !important;
}

.tableDatePicker {
   width: 100%;
   border: 1px solid #d6d6d6;
   transform: translateY(-1px);
   height: 30px;
   color: #565656;
   font-size: 13px;
   text-align: center;
}

.tableDatePicker:focus {
    outline: none;
}


.react-datepicker__triangle{
    transform: translate3d(55px, 0px, 0px) !important;
}
.iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 28px;
    width: 40px;
    border-right: 1px solid #d6d6d6;
}

.icon {
    height: 20px;
    width: auto;
}
.react-datepicker-popper{
    z-index: 3 !important;
}
