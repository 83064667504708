.ant-menu::-webkit-scrollbar{
    display: none;
}

.ant-menu-root{
    padding-bottom: 50px;
    overflow-y: scroll;
}

.ant-menu-item-only-child{
    padding-left: 60px !important;
}


.btn_trigger_clicked {
    position: relative;
    width: 26px;
    cursor: pointer;
    padding: 16px 0px;
    margin:16px 0px 16px 10px;
}

.btn_trigger_clicked span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 1.8px;
    background-color: #747474;
    border-radius: 3px;
}

.btn_trigger_clicked span:nth-of-type(1) {
    top: 0;
  }
.btn_trigger_clicked span:nth-of-type(2) {
    top: 9px;
  }
.btn_trigger_clicked span:nth-of-type(3) {
    top: 18px;
}

.btn_trigger_clicked span:nth-of-type(1) {
    transform: translateY(9px) rotate(-45deg);
    transition: all 0.5s;
}

.btn_trigger_clicked span:nth-of-type(2) {
    opacity: 0;
    transition: all 0.5s;
}

.btn_trigger_clicked span:nth-of-type(3) {
    transform: translateY(-9px) rotate(45deg);
    transition: all 0s;
}

.ant-menu-inline-collapsed{
    width: 55px !important;
}

.ant-menu-inline .ant-menu-sub.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title{
    padding-left: 30px !important;
}

.ant-menu-inline .ant-menu-root{
    padding-left: 40px !important;
}

.ant-menu-inline > .ant-menu-submenu-open > .ant-menu-inline > .ant-menu-item{
    padding-left: 25px !important;
}

.material-icons{
    margin-left: 3px;
    font-size: 20px !important;
}

#logo>.material-icons{
    font-size: 34px !important;
}
.ant-menu-title-content{
    margin-left:20px;
}
.sideBar{
    height: calc(100vh - 59px);
    overflow-y: auto;
}
.sideBar::-webkit-scrollbar {
    display: none;
}
  
.sideBar {
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}

.ant-menu-inline.ant-menu-sub{
    /* background-color: #4a4c50 !important; */
}