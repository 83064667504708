.formSelect {
    margin-right: 22px;
    margin-bottom: 9px;
    width: 218px;
}
.input {

    width: 100%;
    height: 28px;
    padding-left: 5px;
    margin: 14px 0;
    border: solid 1px #D6D6D6;
    appearance: none;
    /* 区切り線 */
    background-image: linear-gradient(0deg, #D6D6D6 50%, transparent 50% );
    background-size:1px 600px;
    background-position:  calc(100% - 33px) 100%;
    background-repeat: no-repeat;
}
.triangle {
    position:absolute;
    top:21px;
    right: 8px;
    width: 0;
    height: 0;
    pointer-events: none;
    border-style: solid;
    border-width: 15px 9px 0 9px;
    border-color: #5D5A5A transparent transparent transparent;
}
.label {
    font-size: 14px;
    color: #333333;
}
.unlabel {
    margin-top: 24px;
}