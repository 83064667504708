.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-select-selection-search-input{
  border-radius: 0px;
}

.ant-menu-dark{
  background: #313239;
}
.ant-menu .ant-menu-submenu-open.ant-menu-submenu-inline>.ant-menu-submenu-title>.ant-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateX(2.5px) translateY(5px);
}
.ant-menu .ant-menu-submenu-open.ant-menu-submenu-inline>.ant-menu-submenu-title>.ant-menu-submenu-arrow::after{
  transform: rotate(40deg) translateX(2.5px) translateY(0px);
}
.ant-menu-inline .ant-menu-submenu-arrow::before{
  transform: rotate(50deg) translateX(2.5px) ;
}
.ant-menu-inline .ant-menu-submenu-arrow::after{
  transform: rotate(130deg) translateX(-2.5px);
}

.ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline{
  background: #313239;
}

.ant-menu-dark.ant-menu-root.ant-menu-inline{
  padding-right: 2px;
}

.ant-menu-dark .ant-menu-submenu-selected >.ant-menu-submenu-title{
  background-color: #4a4c50;
}

.ant-menu-dark .ant-menu-item-selected{
  color: #7a93bd;
    border-radius: 5px;
    background: #ffffff !important;
}
.ant-menu-item:not(.ant-menu-item-selected):active{
  color: #7a93bd;
  border-radius: 5px;
  background: #ffffff !important;
}

.ant-menu-submenu-title:active{
  color: #7a93bd;
  border-radius: 5px;
  background: #ffffff !important;
}

.contentBody{
  background-color: white;
  margin: 0px 15px 0px 15px;
  min-height: calc(100vh - 126px);
}

.bodyLabel{
  display: flex;
  align-items: center;
  height: 50px;
  font-size: 20px;
  margin-left: 50px;
}
.ant-popover-content {
  width: 100px;
}
.ant-popconfirm>.ant-popover-content{
  width: 250px;
}

.descending{
  transform: rotate(180deg);
}