
/* --------------------- page style ----------------- */
.links {
    display: flex;
    margin: 15px 15px 15px 15px;
    width: 1024px;
    flex-wrap: wrap;
    gap: 21px 24px;
}

.cs_card{
    padding: 20px 20px;
    margin-bottom: 20px;
    background-color: #ffffff;
    box-shadow: 4px 4px 10px #3c3b3b;
    border-radius: 7px;
}

.error_tag{
    text-align: center;
}

.error_content{
    color: red;
}
.cs_cat_wrap {
    padding:10px;
    margin:10px;
    border: solid 1px #1a1a1a;
    height:calc(100% - 40px);
}
.cs_item_checkbox {
    float:right;
    cursor:pointer;
    height: 22px;
}
.cs_cat_item {
    padding: 5px 0px;
    height: 22px;
}
.cs_cat_item_title {
    color: #1188E8;
    font-size: 16px;
    font-weight: 600;
}

.cs_item_all_check {
    border: solid 1px #D6D6D6;
    width: 16px;
    height: 30px;
    margin: 14px 15px 0px 15px;
    color: #565656;
    font-size: 16px;
    outline: 0px;
    cursor: pointer;
}
.allcheckwrap {
    width:100px;
    cursor: pointer;
}
.allcheckwrap label {
    cursor: pointer;
}

/* ===================== Modal ============================= */
.search_btn {
    width: 150px;
    height: 32px;
    margin-left: 10px;
    background-color: #1188E8;
    color: #ffffff;
    border: none;
    cursor: pointer;
}
.cs_input_check {
    border: solid 1px #D6D6D6;
    width: 16px;
    height: 30px;
    margin: 14px;
    color: #565656;
    font-size: 16px;
    outline: 0px;
    cursor: pointer;
}
.cs_tbl {
    width:100%;
    margin-bottom: 23px;
    border-collapse: collapse;
    display: block;
    overflow: auto;
    max-height: 400px;
}
.cs_tr {
    display: flex;
}

.cs_th {
    border: solid 1px #767676;
    border-right: none;
    padding: 5px 10px;
    text-align: center;
    background-color: #cfcfcf;

    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 3px;
    padding-left: 3px;
}
.cs_th:last-child {
    border-right: solid 1px #767676;
}
.cs_td {
    border-left: solid 1px #767676;
    border-bottom: solid 1px #767676;
    padding: 5px 10px;
    text-align: center;

    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 3px;
    padding-left: 3px;
}
.cs_td:last-child {
    border-right: solid 1px #767676;
}

.cs_tbl_input {
    border: solid 1px #D6D6D6;
    width: calc(100% - 15px);
    margin: 0px;
    height: 30px;
    padding: 0px 5px;
    color: #565656;
    font-size: 16px;
    outline: 0px;
}

.td_item_align{
    display: flex;
    align-items: center;
    justify-content: center;
}
.standardLabel{
    cursor: pointer;
}

.div_flex{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.div_flex_left{
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 15px;
}

.div_sub_flex_left{
    display: flex;
    justify-content: left;
    align-items: center;
}
.div_body_flex{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    width: 600px;
}
.div_label{
    width: 150px;
    min-width: 150px;
    text-align: left;
    font-size: 16px;
}
.input_left_border_none{
    border-left: none !important;
}
.cs_input {
    border: solid 1px #D6D6D6;
    width: 100%;
    height: 30px;
    padding: 0px 10px;
    color: #565656;
    font-size: 16px;
    outline:0px;
}
.job_time{
    max-width: 120px;
}

.wp5{
    width:5%;
}
.wp10{
    width:10%;
}
.wp15{
    width:15%;
}

.wp20{
    width:20%;
}

.wp30{
    width:30%;
}

.wp70{
    width:70%;
}

