/*====================================================================================================
	単位変更処理 -> AdobeXD 24ページ
	作成日:2022/08/26		最終更新日:2022/08/26
	作成者:GFTD天神-徳渕	最終更新者:GFTD天神-徳渕
====================================================================================================*/

.input_area {
    /* Layout Properties */
    padding-bottom: 2em;
    display: inline-block;
}

.table {
    border-collapse: collapse;

    /* UI Properties */
    opacity: 1;
}