.cs_th {
    border: solid 1px #767676;
    border-right: none;
    padding: 5px 10px;
    text-align: center;
    background-color: #cfcfcf;

    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.cs_th:last-child {
    border-right: solid 1px #767676;
}
.cs_td {
    border-left: solid 1px #767676;
    border-bottom: solid 1px #767676;
    padding: 5px 10px;
    text-align: center;

    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.cs_td:last-child {
    border-right: solid 1px #767676;
}
.w1p { width:170%; }
.w2p { width:150%; }
.w3p { width:180%; }
.w4p { width:130%; }
.w5p { width:200%; }

.cs_tbl_input {
    border: solid 1px #D6D6D6;
    width: calc(100% - 15px);
    margin: 0px;
    height: 30px;
    padding: 0px 5px;
    color: #000000;
    font-size: 16px;
    outline: 0px;
}








