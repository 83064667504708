/* --------------------- page style ----------------- */
.cs_cat_wrap {
    padding:10px;
    margin:10px;
    border: solid 1px #1a1a1a;
    height:calc(100% - 40px);
}
.cs_item_checkbox {
    float:right;
    cursor:pointer;
    height: 22px;
}
.cs_cat_item {
    padding: 5px 0px;
    height: 22px;
}
.cs_cat_item_title {
    color: #1188E8;
    font-size: 16px;
    font-weight: 600;
}
.cs_item_name {
    cursor: pointer;
}
.cs_item_all_check {
    border: solid 1px #D6D6D6;
    width: 16px;
    height: 30px;
    margin: 14px 15px 0px 15px;
    color: #565656;
    font-size: 16px;
    outline: 0px;
    cursor: pointer;
}
.cs_tbl_check {
    border: solid 1px #D6D6D6;
    width: 16px;
    color: #565656;
    font-size: 16px;
    outline: 0px;
    cursor: pointer;
    /* margin: 9px 0px 0px 0px; */
}
.allcheckwrap {
    width:100px;
    cursor: pointer;
}
.allcheckwrap label {
    cursor: pointer;
}
.split_span {
    margin: 0px 5px 9px 5px;
    font-size: 35px;
    line-height: 0px;
}
.item_tbl {
    margin:30px 15px;
}
.postcode_btn_wrap {
    margin-left: 30px;
}
.postcode_btn {
    width: 68px;
    height: 32px;
    background-color: #1188E8;
    color: #ffffff;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
    font-size: 14px;
    cursor: pointer;
}
.postcode_btn:hover {
    background-color: #18ecfd;
}
.cs_tbl_input {
    border: solid 1px #D6D6D6;
    width: calc(100% - 15px);
    margin: 0px;
    height: 30px;
    padding: 0px 5px;
    color: #565656;
    font-size: 13px;
    outline: 0px;
}

/* ===================== Modal ============================= */
.search_btn {
    width: 150px;
    height: 32px;
    margin-left: 10px;
    background-color: #1188E8;
    color: #ffffff;
    border: none;
    cursor: pointer;
}
.cs_input_check {
    border: solid 1px #D6D6D6;
    width: 16px;
    height: 30px;
    margin: 14px;
    color: #565656;
    font-size: 16px;
    outline: 0px;
    cursor: pointer;
}
.tbl_wrap {
    /* overflow: auto; */
    display: flex;
}
.cs_tbl {
    width:100%;
    margin-bottom: 23px;
    border-collapse: collapse;
    display: block;
    overflow: auto;
    max-height: 400px;
}
.cs_tbl_2 {
    width:100%;
    /* width:2500px; */
    margin-bottom: 23px;
    border-collapse: collapse;
    display: block;
}
.cs_tr {
    display: flex;
}
.cs_tbody .cs_tr{
    cursor: pointer;
}
.cs_th {
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px #767676;
    border-right: none;
    padding: 5px 2px;
    background-color: #cfcfcf;
    width: 100%;
    min-height: 1px;
    font-size: 13px;
}
.cs_th:last-child {
    border-right: solid 1px #767676;
}
.cs_td {
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: solid 1px #767676;
    border-bottom: solid 1px #767676;
    padding: 5px 2px;
    width: 100%;
    min-height: 1px;
    font-size: 13px;
}
.cs_td:last-child {
    border-right: solid 1px #767676;
}
.w3p {
    width: 3%;
}
.w4p {
    width: 4%;
}
.w5p {
    width: 5%;
}
.w6p {
    width: 6%;
}
.w7p {
    width: 7%;
}
.w8p {
    width: 8%;
}
.w9p {
    width: 9%;
}
.w10p {
    width: 10%;
}
.w11p {
    width: 11%;
}
.w12p {
    width: 12%;
}
.w13p {
    width: 13%;
}
.w14p {
    width: 14%;
}
.w15p {
    width: 15%;
}
.w20p {
    width: 20%;
}
.total_label {
    background-color: #91ff91;
}
.job_span{
    text-align: end;
    padding-right: 20px;
}
.job_row_align{
   align-items: center;
}
.job_radio_margin{
    margin-left: 20px;
}
.job_time{
    max-width: 120px;
}

.check_label{
    display: flex;
    gap: 3px;
}
.cs_tbl_left_input {
    border: solid 1px #D6D6D6;
    width: calc(20%);
    margin: 0px;
    height: 30px;
    padding: 0px 3px;
    color: #565656;
    font-size: 13px;
    outline: 0px;
}
.cs_tbl_input_right{
    border: solid 1px #D6D6D6;
    width: 30%;
    margin: 0px;
    height: 30px;
    padding: 0px 5px;
    color: #565656;
    font-size: 13px;
    outline: 0px;
}
.cs_tbl_input_center{
    border: solid 1px #D6D6D6;
    border-left: none;
    border-right: none;
    width: 15%;
    margin: 0px;
    height: 30px;
    padding: 0px 5px;
    color: #565656;
    font-size: 13px;
    outline: 0px;
}