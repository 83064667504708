/*====================================================================================================
	作成日:2022/08/25		最終更新日:2022/08/26
	作成者:GFTD天神-徳渕	最終更新者:GFTD天神-徳渕
====================================================================================================*/

.td {
    /* UI Properties */
    border: 1px solid #D6D6D6;
    
    opacity: 1;
}

.text {
    border: none;
    width: 100%;
    height: 100%;

    /* UI Properties */
    opacity: 1;
}