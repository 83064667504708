/* --------------------- page style ----------------- */
.cs_cat_wrap {
    padding:10px;
    margin:10px;
    border: solid 1px #1a1a1a;
    height:calc(100% - 40px);
}
.cs_item_checkbox {
    float:right;
    cursor:pointer;
    height: 22px;
}
.cs_cat_item {
    padding: 5px 0px;
    height: 22px;
}
.cs_cat_item_title {
    color: #1188E8;
    font-size: 16px;
    font-weight: 600;
}
.cs_item_name {
    cursor: pointer;
}
.cs_item_all_check {
    border: solid 1px #D6D6D6;
    width: 16px;
    height: 30px;
    margin: 14px 15px 0px 15px;
    color: #565656;
    font-size: 16px;
    outline: 0px;
    cursor: pointer;
}
.cs_tbl_check {
    border: solid 1px #D6D6D6;
    width: 16px;
    color: #565656;
    font-size: 16px;
    outline: 0px;
    cursor: pointer;
    margin: 9px 0px 0px 0px;
}
.allcheckwrap {
    width:100px;
    cursor: pointer;
}
.allcheckwrap label {
    cursor: pointer;
}
.split_span {
    margin: 0px 5px 9px 5px;
    font-size: 35px;
    line-height: 0px;
}
.item_tbl {
    margin:30px 15px;
}
.postcode_btn_wrap {
    margin-left: 30px;
}
.postcode_btn {
    width: 68px;
    height: 32px;
    background-color: #1188E8;
    color: #ffffff;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
    font-size: 14px;
    cursor: pointer;
}
.postcode_btn:hover {
    background-color: #18ecfd;
}
.cs_tbl_input {
    border: solid 1px #D6D6D6;
    width: calc(100% - 15px);
    margin: 0px;
    height: 30px;
    padding: 0px 5px;
    color: #565656;
    font-size: 16px;
    outline: 0px;
}

/* ===================== Modal ============================= */
.search_btn {
    width: 150px;
    height: 32px;
    margin-left: 10px;
    background-color: #1188E8;
    color: #ffffff;
    border: none;
    cursor: pointer;
}
.cs_input_check {
    border: solid 1px #D6D6D6;
    width: 16px;
    height: 30px;
    margin: 14px;
    color: #565656;
    font-size: 16px;
    outline: 0px;
    cursor: pointer;
}
.tbl_wrap {
    overflow: auto;
}
.cs_tbl {
    width:100%;
    margin-bottom: 23px;
    border-collapse: collapse;
    display: block;
    overflow: auto;
    max-height: 400px;
}
.cs_tbl_2 {
    width:100%;
    margin-bottom: 23px;
    border-collapse: collapse;
    display: initial;
}
.cs_tr {
    display: flex;
}
.cs_tbody .cs_tr{
    cursor: pointer;
}
.cs_th {
    border: solid 1px #767676;
    border-right: none;
    padding: 5px 10px;
    text-align: center;
    background-color: #cfcfcf;

    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.cs_th:last-child {
    border-right: solid 1px #767676;
}
.cs_td {
    border-left: solid 1px #767676;
    border-bottom: solid 1px #767676;
    padding: 5px 10px;
    text-align: center;

    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.cs_td:last-child {
    border-right: solid 1px #767676;
}
.w1p {
    width: 10%;
    min-width: 100px;
}
.w3p {
    width: 20%;
    min-width: 200px;
}
.total_label {
    background-color: #91ff91;
}

.user_btn_update_wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.td_align{
    justify-content: center;
    word-break: break-all;
    display: flex;
    align-items: center;
}

.btn_mt{
    margin-top: 0px;
}

.rol_span{
    padding: 3px 10px;
    background-color: #1aad0db0;
    color: white;
    border-radius: 50%;
    margin: 0px 2px;
}

.btn_up{
    margin-top: 0px !important;
    background-color: #0c9a63 !important;
    width: 70px !important;
    height: 33px;
    font-size: 16px;
    border: none;
    color: #ffffff;
    cursor: pointer;
}
