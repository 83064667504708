/*====================================================================================================
	在庫移動処理 -> AdobeXD 23ページ
	作成日:2022/08/25		最終更新日:2022/08/26
	作成者:GFTD天神-徳渕	最終更新者:GFTD天神-徳渕
====================================================================================================*/

.base {
    /* Layout Properties */
    top: 0px;
    left: 60px;
    width: 1220px;
    margin-bottom: 3em;

    /* UI Properties */
    background: #F1F1F1 0% 0% no-repeat padding-box;
    opacity: 1;
}

.contents {
    /* Layout Properties */
    width: 1000px;
    margin-top: 1em;
    margin-left: 4em;
    padding: 2em;

    /* UI Properties */
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
}

.input_area_1st {
    display: flex;
    flex-flow: row;

    /* Layout Properties */
    padding-bottom: 2em;

    /* UI Properties */
    opacity: 1;
}

.input_area_2nd {
    display: flex;
    flex-flow: row;

    /* Layout Properties */
    padding-bottom: 2em;

    /* UI Properties */
    opacity: 1;
}

.button_area {
    text-align: center;
}