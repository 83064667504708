*,
*::before,
*::after {
  box-sizing: bored-box;
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  background: gray;
}

.app {
  width: 90%;
  margin: 3rem auto;
  height: 400px;
  position: relative;
  background: pink;
}

.element {
  position: absolute;
  height: 35px;
  top: 3px;
  cursor: pointer;
}
.real_element {
  position: absolute;
  height: 10px;
  top: 28px;
  cursor: pointer;
}

.element_in_wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  background: #ec8758;
  border-radius: 4px;
}

.real_element_in_wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  background: #ec8758;
  border-radius: 4px;
}

.element_out_wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  background: #546e5a;
  border-radius: 4px;
}

.other_element_out_wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  background: #777676;
  border-radius: 4px;
}

.real_element_out_wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  background: #7472d9;
  border-radius: 4px;
}

.editPoint__left_in,
.editPoint__right_in {
  position: absolute;
  top: 50%;
  width: 3px;
  height: 25px;
  background: white;
  border: 2px solid #8f490f;
  border-radius: 999px;
  cursor: col-resize;
  opacity: 0;
}

.editPoint__left_out,
.editPoint__right_out {
  position: absolute;
  top: 50%;
  width: 3px;
  height: 25px;
  background: white;
  border: 2px solid #29462f;
  border-radius: 999px;
  cursor: col-resize;
  opacity: 0;
}

.editPoint__left_in {
  left: 0;
  transform: translate(-50%, -50%);
  margin-left: 7px;
}

.editPoint__right_in {
  right: 0;
  transform: translate(50%, -50%);
  margin-right: 7px;
}
.editPoint__left_out {
  left: 0;
  transform: translate(-50%, -50%);
  margin-left: 7px;
}

.editPoint__right_out {
  right: 0;
  transform: translate(50%, -50%);
  margin-right: 7px;
}

.time_line_item{
  background-color: white;
  border-right: 1px solid;
  border-bottom: 1px solid;
  border-color: rgb(218, 211, 211);
}

.time_line{
  position: relative;
}
.cs_flex {
  display:flex;
  align-items: center;
  position: relative;
}

.body_text{
  color: white;
  line-height: 17px;
  margin-left: 10px;
  margin-right: 20px;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  z-index: 1;
  position: sticky;
}