
/* =================== general style =================== */
.display {
    margin-top: 25px;
    margin-left: 3%;
    margin-right: 3%;
}
.text {
    font-size: 18px;
    color: #333333;
}
.border {
    padding-bottom: 5px;
    border-bottom: 1px solid #1188E8;
}
.filed {
    margin-top: 25px;
    background-color: #F1F1F1;
    padding: 0px;
    padding-bottom: 20px;
    border-radius: 7px;
}
.hyfiled {
    display: flex;
}
.cs_select {
    border: solid 1px #D6D6D6;
    width: 100%;
    height: 32px;
    margin: 14px 0;
    padding: 0px 10px;
    color: #565656;
    font-size: 16px;
    outline: 0px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 0px;
    background-size: 30px 30px;
    background-image: url("../components/common/input/icon_select.png");
}
/* ===================== search icon ====================== */
.icon_search_wrap {
    border: solid 1px #D6D6D6;
    border-left: none;
    height: 30px;
    padding: 0px 2px;
}
.icon_search {
    cursor: pointer;
    height: 25px;
    margin-top: 2px;
}
/* ===================== page style ===================== */
.cs_card_1 {
    padding: 35px 20px;
    margin-bottom: 20px;
    background-color: #ffffff;
    box-shadow: 4px 4px 10px #3c3b3b;
    border-radius: 7px;
}
.cs_input_wrap {
    margin-right: 20px;
    margin-bottom: 10px;
}
.cs_input {
    border: solid 1px #D6D6D6;
    width: 100%;
    height: 30px;
    margin: 14px 0;
    padding: 0px 10px;
    color: #565656;
    font-size: 16px;
    outline:0px;
}
.cs_label {
    font-size: 14px;
    color: #333333;
}
.cs_label_blue {
    font-size: 15px;
    font-weight: 600;
    color: #3C8BDC;
}
.cs_span {
    margin-top:10px;
    height: 10px;
}
.cs_flex {
    display:flex;
    align-items: center;
}
.cs_flex_2 {
    display:flex;
    flex-direction: column;
}
.cs_flex_3 {
    display:flex;
    align-items: center;
    justify-content: center;
}
.cs_date_wrap {
    margin: 14px 0px;
    height: 32px;
}
/* ===================== button style ===================== */
.btn_new_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn_update_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
}
.btn_new {
    margin-top: 32px;
    background-color: #1188E8;
    width: 123px;
    height: 33px;
    font-size: 16px;
    border: none;
    color: #ffffff;
    cursor: pointer;
    outline-color: #70b7f1;
}
.btn_delete2 {
    margin-top: 32px;
    margin-left: 5px;
    background-color: #e81f11;
    width: 123px;
    height: 33px;
    font-size: 16px;
    border: none;
    color: #ffffff;
    cursor: pointer;
    outline-color: #eb9089;
}
.btn_update {
    background-color: #1188E8;
    width: 165px;
    height: 48px;
    font-size: 16px;
    border-radius: 40px;
    border: none;
    color: #ffffff;
    cursor: pointer;
    outline: #1188E8;
}
.btn_update:hover, .btn_new:hover {
    background-color: #5b8ff9;
}

.btn_item_update {
    background-color: #168b58;
    width: 165px;
    height: 48px;
    font-size: 16px;
    border-radius: 40px;
    border: none;
    color: #ffffff;
    cursor: pointer;
    outline: #168b58;
}
.btn_item_update:hover {
    background-color: #11e894;
}
.btn_delete {
    background-color: #e81f11;
    width: 165px;
    height: 48px;
    font-size: 16px;
    border-radius: 40px;
    border: none;
    color: #ffffff;
    cursor: pointer;
    margin-left: 20px;
    outline-color: #eb9089;
}
.btn_delete:hover,.btn_delete2:hover {
    background-color: #fd7f18;
}
/* ===================== loading spiner =================== */
.loader_container {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #00000075;
    align-items: center;
    justify-content: center;
    z-index: 10000;
}
.loader_container span {
    border-color: #1188E8 #1188E8 transparent !important;
    border-width: 6px !important;
}

/* ===================== col ===================== */
.cs_row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    /* margin-right: -15px;
    margin-left: -15px; */
}
.cs_col {
    position: relative;
    width: 100%;
    min-height: 1px;
    /* padding-right: 15px;
    padding-left: 15px; */
}
.cs_col_1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
}
.cs_col_2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666666%;
    flex: 0 0 16.666666%;
    max-width: 16.666666%;
}
.cs_col_3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}
.cs_col_4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}
.cs_col_5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666666%;
    flex: 0 0 41.666666%;
    max-width: 41.666666%;
}
.cs_col_6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}
.cs_col_8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}
.cs_col_9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
}
.cs_col_10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
}
.cs_col_12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}
.cs_col_1p {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 1%;
    flex: 0 0 1%;
    max-width: 1%;
}
.cs_col_3p {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 3%;
    flex: 0 0 3%;
    max-width: 3%;
}
.cs_col_5p {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 5%;
    flex: 0 0 5%;
    max-width: 5%;
}
.cs_col_10p {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 10%;
    flex: 0 0 10%;
    max-width: 10%;
}
.cs_col_15p {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 15%;
    flex: 0 0 15%;
    max-width: 15%;
}
.cs_col_20p {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
}

.w5 { width:5%; }
.w10 { width:10%; }
.w15 { width:15%; }
.w20 { width:20%; }
.w22 { width:22%; }
.w25 { width:25%; }
.w30 { width:30%; }
.w35 { width:35%; }
.w40 { width:40%; }
.w45 { width:45%; }
.w50 { width:50%; }
.w55 { width:55%; }
.w80 { width:80%; }
.w100 { width:100%; }
.w50px { width:50px !important; }
.w60px { width:60px; }
.w70px { width:70px; }
.w80px { width:80px; }
.w100px { width:100px; }
.w120px { width:120px; }
.w140px { width:140px; }
.w170px { width:170px; }
.w220px { width:220px; }
.w300px { width:300px; }
.w345px { width:345px; }
.w445px { width:445px; }
.mt5 { margin-top:5px; }
.mt30 { margin-top:30px; }
.p0015 { padding: 0px 15px; }
.p00300015 { padding: 0px 30px 0px 15px; }

/* ========================================================== */
.cs_bl_n {
    border-left:none;
}
.bg_tbl_total {
    background-color: #ebebebbd;
}

.save_confirm_yes{
    /* margin-top: 32px; */
    background-color: #1188E8;
    width: 80px;
    height: 30px;
    font-size: 14px;
    border: none;
    color: #ffffff;
    cursor: pointer;
}
.save_confirm_yes:hover {
    background-color: #5b8ff9;
    color: #ffffff !important;
}

.save_confirm_no{
    /* margin-top: 32px; */
    margin-right: 5px;
    background-color: #e74438;
    width: 80px;
    height: 30px;
    font-size: 14px;
    border: none;
    color: #ffffff;
    cursor: pointer;
}
.save_confirm_no:hover {
    background-color: #d95950;
    color: #ffffff !important;
}
