.display {
    margin-top: 25px;
    margin-left: 3%;
    margin-right: 3%;
    height: 800px;
}
.text {
    font-size: 18px;
    color: #333333;
}
.border {
    padding-bottom: 5px;
    border-bottom: 1px solid #1188E8;
}
.filed {
    background-color: #ffffff;
    margin-top: 25px;
    padding: 34px;
    height: 800px;
}
.flex {
    display: flex;
    flex-wrap: wrap;
} 
.button {
    background-color: #1188E8;
    border: none;
    margin-left: 324px;
    width: 223px;
    height: 52px;
    font-size: 16px;
    color: #ffffff;
}
.container {
    border: solid 1px #D6D6D6;
    border-radius: 20px;
    padding: 37px 27px;
    padding-bottom: 0;
}
.table {
    border-collapse: collapse;
    margin-top: 40px;
}
.tableHeader {
    background-color: #DEDBDB;
    border: solid 1px #D6D6D6;
    font-weight: 100;
    font-size: 14px;
    height: 30px;
    color: #333333;
}
.showtable {
    width: 1500px;
}