/*====================================================================================================
	在庫移動処理 -> AdobeXD 23ページ
	作成日:2022/08/25		最終更新日:2022/08/26
	作成者:GFTD天神-徳渕	最終更新者:GFTD天神-徳渕
====================================================================================================*/

.flex {
    display: flex;
    flex-flow: column;

    /* UI Properties */
    opacity: 1;
}

.title {
    /* Layout Properties */
    padding-bottom: 0.5em;
}

.input_text {
    /* Layout Properties */
    width: 198px;
    height: 28px;
}