.datepicker_wrapper {
    position: relative;
    /* width:100%; */
    height: 28px;
    background-color: white;
    border-top: 1px solid #d6d6d6;
    display: flex;
    justify-content: flex-start;
}

div.react-datepicker-wrapper {
    /* flex: 8; */
}

div.react-datepicker__input-container {
    /* width: 100%; */
    height: 100%;
}
.formDatePicker {
   /* width: 100%; */
   min-width: 130px;
   border: 1px solid #d6d6d6;
   height: 30px;
   color: #565656;
   font-size: 16px !important;
   text-align: center;
}

.formDatePicker:focus {
    outline: none;
}


.react-datepicker__triangle{
    transform: translate3d(120px, 0px, 0px) !important;
}
.iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 28px;
    width: 40px;
    border-right: 1px solid #d6d6d6;
}

.icon {
    height: 20px;
    width: auto;
}
.react-datepicker-popper{
    z-index: 3 !important;
}
