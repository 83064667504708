.w10 { width:10% !important;}
.w20 { width:20% !important;}
.w30 { width:30% !important;}
.w40 { width:40% !important;}
.w50 { width:50% !important;}
.w80 { width:80% !important;}
.w60px { width:60px !important;}
.w80px { width:80px !important;}
.w150px { width:150px !important;}
.w180px { width:180px !important;}
.w200px { width:200px !important;}
.w250px { width:250px !important;}
.w300px { width:300px !important;}
.w400px { width:400px !important;}
.mt5 { margin-top:5px !important;}
.mt10 { margin-top:10px !important;}
.mt15 { margin-top:15px !important;}
.mt20 { margin-top:20px !important;}
.mt25 { margin-top:25px !important;}
.mt30 { margin-top:30px !important;}

.container {
    min-height: 600px;
    width: 100%;
    overflow: auto;
    text-align: center;
    padding: 30px 0;
}

.title {
    font-size: 40px;
    font-weight: 800;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 20px;
}
/* ===================== loading spiner =================== */
.loader_container {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #00000075;
    align-items: center;
    justify-content: center;
    z-index: 1;
}
.loader_container span {
    border-color: #1188E8 #1188E8 transparent !important;
    border-width: 6px !important;
}

.cs_flex {
    display:flex;
    align-items: center;
    justify-content: center;
}

.cs_flex_gap {
    display:flex;
    align-items: center;
    justify-content: center;
    column-gap: 25px;
}

.ps5 {
    min-width: 80px;
    min-height: 70px;
    width: 15%;
}

.gift {
    min-width: 300px;
    min-height: 200px;
    width: 35%;
}

.card {
    width: 250px;
    margin-top: 20px;
}

.subtitle {
    font-size: 35px;
    font-weight: 800;
    text-align: left;
}

.itemtitle {
    font-size: 25px;
    font-weight: 700;
    margin-top: 15px;
    text-align: left;
}

.select {
    width: 200px;
    height: 30px;
    border: solid 3px #8FAADC;
    padding-left: 5px;
}

.comment {
    font-size: 15px;
    color: white;
    line-height: 120%;
    margin: 15px 15px;
    text-align: center;
}

.terms2 {
    font-size: 14px;
    color: white;
}

.terms {
    font-size: 14px;
    color: #00B0F0;
    margin-left: 10px;
    border-bottom: 1px solid #00B0F0;
    cursor: pointer;
}

.links {
    display: flex;
    margin: 20px 0px 20px 65px;
    width: 960px;
    flex-wrap: wrap;
    gap: 21px 24px;
}

.form_calss {
    display: none;
}

.add_btn_div {
    display: flex;
    justify-content: flex-end;
    margin-right: 30px;
}

.link {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    /* width: 200px;
    height: 40px; */
    text-decoration: underline;
    color: #36C1F3;
    font-size: 20px;
    text-align: center;
    border: none;
    cursor: pointer;
}

.tb_container {
    margin: 30px 30px;
    width: max-content;
}
.tb_header {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 100%; */
    border: black solid 1px;
}

.tb_item {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 100%; */
    border: black solid 1px;
    border-top: none;
}
.edit_item {
    width: 100%;
    height: 25px;
    margin: 0px 5px;
    padding-left: 5px;
    font-size: 17px;
}
.col1 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 35px;
}
.col2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 35px;
    border-left: black solid 1px;
}
.col3 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 35px;
    border-left: black solid 1px;
}
.col4 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
    height: 35px;
    border-left: black solid 1px;
}

.add_btn {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 40px;
    background-color: yellow;
    text-decoration: none;
    color: black;
    font-size: 20px;
    text-align: center;
    font-weight: 700;
    border-radius: 10px;
    cursor: pointer;
}

.price {
    font-size: 30px;
    text-align: right;
    font-weight: 700;
}

.btn_container {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ul_style {
    list-style: inside;
}

.link {
    text-decoration: underline;
    color: #0070C0;
    font-size: 30px;
    font-weight: 700;
    text-align: left;
    border: none;
    cursor: pointer;
}

.qr_container {
    padding: 30px 30px;
    margin: 10px 100px;
    width: 400px;
    /* background: black; */
}

.QRcode {
    width: 200px;
    height: 200px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.white_color {
    color: white;
    text-align: center;
}