




/* --------------------- page style ----------------- */
















.cs_cat_wrap {
    padding:10px;
    margin:10px;
    border: solid 1px #1a1a1a;
    height:calc(100% - 40px);
}
.cs_item_checkbox {
    float:right;
    cursor:pointer;
    height: 22px;
}
.cs_cat_item {
    padding: 5px 0px;
    height: 22px;
}
.cs_cat_item_title {
    color: #1188E8;
    font-size: 16px;
    font-weight: 600;
}
.cs_item_name {
    cursor: pointer;
}
.cs_item_all_check {
    border: solid 1px #D6D6D6;
    width: 16px;
    height: 30px;
    margin: 14px 15px 0px 15px;
    color: #565656;
    font-size: 16px;
    outline: 0px;
    cursor: pointer;
}
.cs_tbl_check {
    border: solid 1px #D6D6D6;
    width: 16px;
    color: #565656;
    font-size: 16px;
    outline: 0px;
    cursor: pointer;
    margin: 9px 0px 0px 0px;
}
.allcheckwrap {
    width:100px;
    cursor: pointer;
}
.allcheckwrap label {
    cursor: pointer;
}
.split_span {
    margin: 0px 5px 9px 5px;
    font-size: 35px;
    line-height: 0px;
}
.item_tbl {
    margin:30px 30px;
}
.postcode_btn_wrap {
    margin-left: 30px;
}
.postcode_btn {
    width: 68px;
    height: 32px;
    background-color: #1188E8;
    color: #ffffff;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
    font-size: 14px;
    cursor: pointer;
}
.postcode_btn:hover {
    background-color: #18ecfd;
}
.cs_tbl_input {
    border: solid 1px #D6D6D6;
    width: calc(100% - 15px);
    margin: 0px;
    height: 30px;
    padding: 0px 5px;
    color: #565656;
    font-size: 16px;
    outline: 0px;
}

/* ===================== Modal ============================= */
.search_btn {
    width: 150px;
    height: 32px;
    margin-left: 10px;
    background-color: #1188E8;
    color: #ffffff;
    border: none;
    cursor: pointer;
}
.cs_input_check {
    border: solid 1px #D6D6D6;
    width: 16px;
    height: 30px;
    margin: 14px;
    color: #565656;
    font-size: 16px;
    outline: 0px;
    cursor: pointer;
}
.tbl_wrap {
    overflow: auto;
    display: block;
}
.cs_tbl {
    width:100%;
    margin-bottom: 23px;
    border-collapse: collapse;
    display: block;
    overflow: auto;
    max-height: 400px;
}
.cs_tbl_2 {
    /* width:100%; */
    width:2500px;
    margin-bottom: 23px;
    border-collapse: collapse;
    display: block;
}
.cs_tr {
    display: flex;
}
.cs_tbody .cs_tr{
    cursor: pointer;
}
.cs_th {
    border: solid 1px #767676;
    border-right: none;
    padding: 5px 10px;
    text-align: center;
    background-color: #cfcfcf;

    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.cs_th:last-child {
    border-right: solid 1px #767676;
}
.cs_td {
    border-left: solid 1px #767676;
    border-bottom: solid 1px #767676;
    padding: 5px 10px;
    text-align: center;

    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.cs_td:last-child {
    border-right: solid 1px #767676;
}
.w1p {
    width: 6%;
}
.w2p {
    width: 12%;
}
.w3p {
    width: 5%;
}
.w4p {
    width: 6%;
}
.w5p {
    width: 10%;
}
.w6p {
    width: 6%;
}
.w7p {
    width: 7%;
}
.total_label {
    background-color: #91ff91;
}
/* ==================== New Widow ========================= */
.nw_container {
    background: #ffffff;
    width: 100%;
    height: 100%;
}
.p2030 {
    padding: 20px 30px;
}
.nw_title_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
.nw_title_wrap h2 {
    font-size:20px;
}
.publish_wrap {
    text-align: end;
    margin-right: 35px;
    margin-top:20px;
}
.cs_span_2 {
    margin-left:15px; margin-top: 15px;
}
table {
    width: 100%;
}
table, th, td {
    border: 1px solid #424242;
    ;
    border-collapse: collapse;
}
th {
    font-size: 16px;
    color: #424242;
    background-color: #c7c7c7;
}
td {
    font-size: 15px;
    color: #424242;
    text-align: center;
    padding: 3px 3px;
}
.btn_print {
    background-color: #1188E8;
    width: 123px;
    height: 33px;
    font-size: 16px;
    border: none;
    color: #ffffff;
    cursor: pointer;
}
.btn_csv {
    background-color: rgb(17, 136, 232);
    width: 123px;
    height: 33px;
    font-size: 16px;
    border: none;
    color: rgb(255, 255, 255);
    cursor: pointer;
    margin-left: 10px;
    text-decoration: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn_print:hover, .btn_csv:hover {
    background-color: #18ecfd;
}
.grp_print_wrap {
    padding: 20px 30px;
    justify-content: center;
    align-items: center;
    display: flex;
}
.bg_tbl_total {
    background-color: #ebebebbd;
}
.job_span{
    text-align: end;
    padding-right: 20px;
    /* font-size: 18px;
    font-weight: 600; */
}
.job_row_align{
   align-items: center;
}
.job_radio_margin{
    margin-left: 20px;
}
.job_time{
    max-width: 120px;
}
.time_line_name{
    min-width: 180px;
    max-width: 180px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #afabab;
    border-right: 2px solid#afabab;
    padding-left: 10px;
    background-color: aliceblue;
}
.time_line_body{
    display: grid;
    overflow: auto;
    height: calc(100vh - 250px);
    border-left: 1px solid #afabab;

}
.time_line_header{
    display: flex;
    height: 40px;
}
.time_line_name_header{
    min-width: 180px;
    max-width: 180px;
    padding-left: 10px;
    border: 1px solid #434748;

    border-right: 2px solid #ffffff;
    background-color: #434748;
    color: white;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;}
.time_line_header_body{
    border-right: 1px solid;
    background-color: #434748;
    border-right-color: #434748;
    display: flex;
    align-items: flex-end;
    border-top: 1px solid;
    border-top-color:  #afabab;
}
.time_line_header_body_l{
    border-right: 1px solid;
    background-color: #434748;
    border-right-color: #afabab;
    display: flex;
    align-items: flex-end;
    border-top: 1px solid;
    border-top-color:  #afabab;
}
.time_line_header_other_body{
    border-right: 1px solid;
    background-color: #434748;
    border-color: #ffffff;
    display: flex;
    align-items: end;
    justify-content: center;
    border: 1px solid #ffffff;
    border-top: 1px solid #434748;
    border-left: none;
}
.time_line_header_body_item{
    /* margin-left: -5px; */
    color: rgb(255, 255, 255);
    font-size: 12px;
}
.time_line_header_body_other_item{
    color: rgb(255, 255, 255);
    font-size: 12px;
}
.total_row{
    background-color: white;
}
.time_name_body{
    align-items: center;
    width: 100%;
}
.time_name_sub1{
    font-size: 20px;
}
.time_name_sub2{
    font-size: 15px;
    opacity: 0.8;
}
.up_down{
    width: 15px;
    cursor: pointer;
}
.arrow_div{
    height: 99%;
    width: 20px;
    justify-content: end;
    align-items: end;
    display: flex;
    margin-right: 5px;
}
.tab1{
    position: fixed;
    right: -300px;
    display: flex;
    width: 300px;
    top: 223px;
    z-index: 2;
}
.tab2{
    position: fixed;
    right: -300px;
    display: flex;
    width: 300px;
    top: 357px;
    z-index: 2;
}
.tab1_but{
    position: fixed;
    right: -280px;
    display: flex;
    width: 300px;
    top: 223px;
    z-index: 1;
}
.tab2_but{
    position: fixed;
    right: -280px;
    display: flex;
    width: 300px;
    top: 357px;
    z-index: 1;
}
.span_label{
    word-break: break-all;
    position: fixed;
    margin-left: 2px;
    max-width: 20px;
}
.img{
    display: block;
    width: 11px;
    margin-top: 5px;
    margin-left: 4px;
    margin-bottom: 4px;
}

.caption{
    min-width: 20px;
    height: 130px;
    border: 1px solid;
    border-right: none;
    /* border-top-left-radius: 5px;
    border-bottom-left-radius: 5px; */
    border-color: #a39b9b;
    background-color: #fffcfc;
    cursor: pointer;
}

.tab_body{
    width: 300px;
    min-height: calc(100vh - 230px);
    background-color: #fffcfc;
    border: 1px solid;
    border-color: #9f9b9b;
    overflow-y: auto;
    max-height: calc(100vh - 230px);
}

.sub_body1{
    text-align: start;
    padding: 5px;
    margin: 5px;
    cursor: pointer;
    background-color: #43acff;
}

.sub_body2{
    text-align: start;
    padding: 5px;
    margin: 5px;
    cursor: pointer;
    background-color:#58d772
}
.sub_p1{
    padding-left: 10px;
}
.sub_p2{
    padding-left: 3px;
    font-size: 12px;
    margin-top: -3px;
}
.time_line_item{
    background-color: #fbf8f3;
    border-right: 1px solid;
    border-bottom: 1px solid;
    border-color: rgb(218, 211, 211);
}
.start_date_time{
    margin: 10px;
}
.element {
    position: relative;
    width: 100px;
    height: 35px;
    top: 3px;
  }
  
  .element__wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    background: red;
  }
  
  .editPoint__left,
  .editPoint__right {
    position: absolute;
    top: 50%;
    width: 15px;
    height: 15px;
    background: white;
    border: 2px solid blue;
    border-radius: 999px;
    cursor: pointer;
  }
  
  .editPoint__left {
    left: 0;
    transform: translate(-50%, -50%);
  }
  
  .editPoint__right {
    right: 0;
    transform: translate(50%, -50%);
  }

  .cs_select {
    border: solid 1px #D6D6D6;
    width: 100%;
    height: 32px;
    padding: 0px 10px;
    color: #565656;
    font-size: 16px;
    outline: 0px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 0px;
    background-size: 30px 30px;
    background-image: url("../../components/common/input/icon_select.png");
}
.cs_input {
    border: solid 1px #D6D6D6;
    width: 100%;
    height: 30px;
    padding: 0px 10px;
    color: #565656;
    font-size: 16px;
    outline:0px;
}
.cs_but_rn{
    height: 30px;
    width: 40px;
    border: solid 1px #D6D6D6;
    border-right: none;
    cursor: pointer;

}
.cs_but{
    height: 30px;
    width: 40px;
    border: solid 1px #D6D6D6;
    cursor: pointer;

}
.cs_dw_but{
    height: 30px;
    border: solid 1px #D6D6D6;
    padding: 5px 10px;
}
.but_div{
    justify-content: end;
    display: flex;
}
.label_div{
    justify-content: center;
    display: flex;
    align-items: center;
}
.ml_auto{
    margin-left: auto;
}

.display {
    /* margin-top: 25px; */
    /* margin-left: 3%;
    margin-right: 3%; */
    background-color: white;
}

.body_div_m{
    /* margin-top: 25px; */
    padding-top: 20px;
    margin-left: 3%;
    margin-right: 3%;
}

.shipper_name{
    font-size: 18px;
    font-weight: 500;
    color: #e53e18;
}
.user_tel_label{
    font-size: 13px;
    margin-left: 10%;
    color: #444646;
}
/* * {
    -webkit-touch-callout:none;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
} */