/* ------------------------ general style --------------------------- */
.display {
    margin-top: 25px;
    margin-left: 3%;
    margin-right: 3%;
    height: 800px;
}
.text {
    font-size: 18px;
    color: #333333;
}
.border {
    padding-bottom: 5px;
    border-bottom: 1px solid #1188E8;
}
.filed {
    margin-top: 25px;
    /* background-color: #ffffff;
    padding: 34px; */
    background-color: #F1F1F1;
    padding: 0px;
    padding-bottom: 60px;
    border-radius: 7px;
}
.hyfiled {
    display: flex;
}
.btn_update_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
}
.btn_update {
    background-color: #1188E8;
    width: 165px;
    height: 48px;
    font-size: 16px;
    border-radius: 40px;
    border: none;
    color: #ffffff;
    cursor: pointer;
}
.btn_update:hover {
    background-color: #18ecfd;
}
.btn_delete {
    background-color: #e81f11;
    width: 165px;
    height: 48px;
    font-size: 16px;
    border-radius: 40px;
    border: none;
    color: #ffffff;
    cursor: pointer;
    margin-left: 20px;
}
.btn_delete:hover {
    background-color: #fd7f18;
}
/* ---------------------- col ---------------------------- */
.cs_row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    /* margin-right: -15px;
    margin-left: -15px; */
}
.cs_col {
    position: relative;
    width: 100%;
    min-height: 1px;
    /* padding-right: 15px;
    padding-left: 15px; */
}
.cs_col_1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
}
.cs_col_2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666666%;
    flex: 0 0 16.666666%;
    max-width: 16.666666%;
}
.cs_col_3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}
.cs_col_4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}
.cs_col_5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666666%;
    flex: 0 0 41.666666%;
    max-width: 41.666666%;
}
.cs_col_6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}
.cs_col_8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}
.cs_col_12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}
/* --------------------- page style ----------------- */
.cs_card_1 {
    padding: 35px 20px;
    margin-bottom: 20px;
    background-color: #ffffff;
    box-shadow: 4px 4px 10px #3c3b3b;
    border-radius: 7px;
}
.cs_input_wrap {
    margin-right: 20px;
    margin-bottom: 10px;
}
.cs_input {
    border: solid 1px #D6D6D6;
    width: 100%;
    height: 30px;
    margin: 14px 0;
    padding: 0px 10px;
    color: #565656;
    font-size: 16px;
    outline:0px;
}
.cs_label {
    font-size: 14px;
    color: #333333;
}
.cs_label_blue {
    font-size: 15px;
    font-weight: 600;
    color: #3C8BDC;
}
.cs_span {
    margin-top:10px;
}
.w10 { width:10%; }
.w20 { width:20%; }
.w30 { width:30%; }
.w40 { width:40%; }
.w50 { width:50%; }
.w80 { width:80%; }
.w100px { width:100px; }
.w200px { width:200px; }
.w220px { width:220px; }
.w245px { width:245px; }
.mt5 { margin-top:5px; }
.p0015 { padding: 0px 15px; }

.cs_flex {
    display:flex;
    align-items: center;
}
.cs_flex_2 {
    display:flex;
    flex-direction: column;
}
.cs_cat_wrap {
    padding:10px;
    margin:10px;
    border: solid 1px #1a1a1a;
    height:calc(100% - 40px);
}
.cs_item_checkbox {
    float:right;
    cursor:pointer;
    height: 22px;
}
.cs_cat_item {
    padding: 5px 0px;
    height: 22px;
}
.cs_cat_item_title {
    color: #1188E8;
    font-size: 16px;
    font-weight: 600;
}
.cs_item_name {
    cursor: pointer;
}
.cs_item_all_check {
    border: solid 1px #D6D6D6;
    width: 16px;
    height: 30px;
    margin: 14px 15px 0px 15px;
    color: #565656;
    font-size: 16px;
    outline: 0px;
    cursor: pointer;
}
.allcheckwrap {
    width:100px;
    cursor: pointer;
}
.allcheckwrap label {
    cursor: pointer;
}
.cs_date_wrap {
    margin: 14px 0px;
    height: 32px;
}
/* ===================== loading spiner =================== */
.loader_container {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #00000075;
    align-items: center;
    justify-content: center;
    z-index: 1;
}
.loader_container span {
    border-color: #1188E8 #1188E8 transparent !important;
    border-width: 6px !important;
}
/* ===================== search icon ====================== */
.icon_search_wrap {
    border: solid 1px #D6D6D6;
    height: 30px;
    padding: 0px 2px;
}
.icon_search {
    cursor: pointer;
    height: 25px;
    margin-top: 2px;
}
/* ===================== Modal ============================= */
.search_btn {
    width: 150px;
    height: 32px;
    margin-left: 10px;
    background-color: #1188E8;
    color: #ffffff;
    border: none;
    cursor: pointer;
}
.cs_input_check {
    border: solid 1px #D6D6D6;
    width: 16px;
    height: 30px;
    margin: 14px;
    color: #565656;
    font-size: 16px;
    outline: 0px;
    cursor: pointer;
}
.cs_tbl {
    width:100%;
    margin-bottom: 23px;
    border-collapse: collapse;
    display: block;
    overflow: auto;
    max-height: 400px;
}
.cs_tr {
    display: flex;
}
.cs_tbody .cs_tr{
    cursor: pointer;
}
.cs_th {
    border: solid 1px #767676;
    border-right: none;
    padding: 5px 10px;
    text-align: center;
    background-color: #cfcfcf;

    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.cs_th:last-child {
    border-right: solid 1px #767676;
}
.cs_td {
    border-left: solid 1px #767676;
    border-bottom: solid 1px #767676;
    padding: 5px 10px;
    text-align: center;

    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.cs_td:last-child {
    border-right: solid 1px #767676;
}
.cs_td_left {
    border-left: solid 1px #767676;
    border-bottom: solid 1px #767676;
    padding: 5px 10px;
    text-align: left;
    line-break: anywhere;

    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.cs_td_left:last-child {
    border-right: solid 1px #767676;
}
.cs_td_right {
    border-left: solid 1px #767676;
    border-bottom: solid 1px #767676;
    padding: 5px 10px;
    text-align: right;
    line-break: anywhere;

    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.cs_td_right:last-child {
    border-right: solid 1px #767676;
}

.cs_tbl_input {
    border: solid 1px #D6D6D6;
    width: calc(100% - 15px);
    margin: 0px;
    height: 30px;
    color: #565656;
    font-size: 13px;
    outline: 0px;
    padding: 0px 5px;
}
.line_input {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 1px;
    font-size: 13px;
}
.w20p {
    width: 20%;
}
.job_time{
    max-width: 120px;
}
