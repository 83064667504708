.sidebar {
    /* z-index: 1; */
    color: white;
}

.logo_area {
    width: 220px;
    height: 59px;
    background-color: white;
}

.logo {
    width: 160px;
    height: 30px;
}

.logo_close {
    /* margin: 14px 0px 14px 10px; */
    width: 180px;
    height: 30px;
    margin: 10px;
}

.first {
    margin-top: 70px;
}

.link {
    display:flex;
    /* justify-content: center; */
    align-items: center;
    font-size: 16px;
    /* width: 190px;
    height: 48px; */
}

.link:link, .link:visited, .link:hover, .link:active {
    color: white;
    text-decoration: none;
}

.btnLogout {
    display:flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    width: 190px;
    height: 48px;
    background-color: #333333;
    color: #ffffff;
    cursor: pointer;
    border: none;
    width: 100%;
}


.left_area {
    z-index: 1;
    width: 220px;
    min-width: 220px;
    background-color: white;
    display: flex;
    transition: all 0.4s cubic-bezier(0.2, 0, 0, 1) 0s;
    z-index: 2;
    height: 59px;
}

.left_area_moved {
    min-width: 250px;
    width: 250px;
    background-color: white;
    transform: translateX(-160px);
    transition: all 0.4s cubic-bezier(0.2, 0, 0, 1) 0s;
    z-index: 2;
    height: 59px;
}

.btn_trigger_clicked {
    position: relative;
    width: 22px;
    cursor: pointer;
    padding: 16px 0px;
    margin:20px 0px 16px 10px;
}

.btn_trigger_clicked span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 1.8px;
    background-color: #747474;
    border-radius: 3px;
}

.btn_trigger_clicked span:nth-of-type(1) {
    top: 2px;
  }
.btn_trigger_clicked span:nth-of-type(2) {
    top: 9px;
  }
.btn_trigger_clicked span:nth-of-type(3) {
    top: 16px;
}
/* 
.btn_trigger_clicked span:nth-of-type(1) {
    transform: translateY(9px) rotate(-45deg);
    transition: all 0.5s;
}

.btn_trigger_clicked span:nth-of-type(2) {
    opacity: 0;
    transition: all 0.5s;
}

.btn_trigger_clicked span:nth-of-type(3) {
    transform: translateY(-9px) rotate(45deg);
    transition: all 0s;
} */

.btn_trigger {
    position: relative;
    width: 22px;
    cursor: pointer;
    padding: 16px 0px;
    margin:20px 0px 16px 10px;
}

.btn_trigger span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 1.8px;
    background-color: #747474;
    border-radius: 3px;
}

.btn_trigger, .btn_trigger span {
    display: inline-block;
    transition: all .5s;
    box-sizing: border-box;
}

.btn_trigger span:nth-of-type(1) {
    top: 2px;
  }
.btn_trigger span:nth-of-type(2) {
    top: 9px;
  }
.btn_trigger span:nth-of-type(3) {
    top: 16px;
}
.badge{
    margin-left: 10px;
    width: 20px;
    height: 20px;
    background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}
.div_badge{
    display: flex;
    justify-content: end;
    width: 100%;
}