
/* --------------------- page style ----------------- */














.m0010 { margin:0px 10px;}




.cs_cat_wrap {
    padding:10px;
    margin:10px;
    border: solid 1px #1a1a1a;
    height:calc(100% - 40px);
}
.cs_item_checkbox {
    float:right;
    cursor:pointer;
    height: 22px;
}
.cs_cat_item {
    padding: 5px 0px;
    height: 22px;
}
.cs_cat_item_title {
    color: #1188E8;
    font-size: 16px;
    font-weight: 600;
}
.cs_item_name {
    cursor: pointer;
}
.cs_item_all_check {
    border: solid 1px #D6D6D6;
    width: 16px;
    height: 30px;
    margin: 14px 15px 0px 15px;
    color: #565656;
    font-size: 16px;
    outline: 0px;
    cursor: pointer;
}
.cs_tbl_check {
    border: solid 1px #D6D6D6;
    width: 16px;
    color: #565656;
    font-size: 16px;
    outline: 0px;
    cursor: pointer;
    margin: 9px 0px 0px 0px;
}
.allcheckwrap {
    width:100px;
    cursor: pointer;
}
.allcheckwrap label {
    cursor: pointer;
}
.split_span {
    margin: 0px 5px 9px 5px;
    font-size: 35px;
    line-height: 0px;
}
.item_tbl {
    margin:30px 30px;
}
.postcode_btn_wrap {
    margin-left: 30px;
}
.postcode_btn {
    width: 68px;
    height: 32px;
    background-color: #1188E8;
    color: #ffffff;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
    font-size: 14px;
    cursor: pointer;
}
.postcode_btn:hover {
    background-color: #18ecfd;
}
.cs_tbl_input {
    border: solid 1px #D6D6D6;
    width: calc(100% - 15px);
    margin: 0px;
    height: 30px;
    padding: 0px 5px;
    color: #565656;
    font-size: 16px;
    outline: 0px;
}
/* ===================== summary =========================== */
.summary_wrap {
    background-color: #f3f3f3;
    padding: 15px 35px;
    width: calc(100% - 70px);
    box-shadow: 3px 3px 10px #6e6e6e;
    margin-top: 30px;
}
.summary_title_wrap {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    margin: 5px 0px 15px 0px;
}
.summary_title_span {
    border-bottom: solid 1px;
}
.summary_b_txt_span {
    font-size: 19px;
    font-weight: 600;
    margin: 0px 10px;
}
/* ===================== Modal ============================= */
.search_btn {
    width: 150px;
    height: 32px;
    margin-left: 10px;
    background-color: #1188E8;
    color: #ffffff;
    border: none;
    cursor: pointer;
}
.cs_input_check {
    border: solid 1px #D6D6D6;
    width: 16px;
    height: 30px;
    margin: 14px;
    color: #565656;
    font-size: 16px;
    outline: 0px;
    cursor: pointer;
}
.tbl_wrap {
    overflow: auto;
    display: block;
}
.cs_tbl {
    width:100%;
    margin-bottom: 23px;
    border-collapse: collapse;
    display: block;
    overflow: auto;
    max-height: 400px;
}
.cs_tbl_2 {
    /* width:100%; */
    width:2500px;
    margin-bottom: 23px;
    border-collapse: collapse;
    display: block;
}
.cs_tr {
    display: flex;
}
.cs_tbody .cs_tr{
    cursor: pointer;
}
.cs_th {
    border: solid 1px #767676;
    border-right: none;
    padding: 5px 10px;
    text-align: center;
    background-color: #cfcfcf;

    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.cs_th:last-child {
    border-right: solid 1px #767676;
}
.cs_td {
    border-left: solid 1px #767676;
    border-bottom: solid 1px #767676;
    padding: 5px 10px;
    text-align: center;

    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.cs_td:last-child {
    border-right: solid 1px #767676;
}
.w1p {
    width: 6%;
}
.w2p {
    width: 12%;
}
.w3p {
    width: 5%;
}
.w4p {
    width: 6%;
}
.w5p {
    width: 10%;
}
.w6p {
    width: 6%;
}
.w7p {
    width: 7%;
}
.total_label {
    background-color: #91ff91;
}
/* ==================== New Widow ========================= */
.nw_container {
    background: #ffffff;
    width: 100%;
    height: 100%;
}
.p2030 {
    padding: 20px 30px;
}
.nw_title_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
.nw_title_wrap h2 {
    font-size:20px;
}
.publish_wrap {
    position: fixed;
    right: 30px;
    top: 0px;
    margin-top:20px;
}
.cs_span_2 {
    margin-left:15px; margin-top: 15px;
}
table {
    width: 100%;
}
table, th, td {
    border: 1px solid #424242;
    ;
    border-collapse: collapse;
}
th {
    font-size: 16px;
    color: #424242;
    background-color: #c7c7c7;
}
td {
    font-size: 15px;
    color: #424242;
    text-align: center;
    padding: 3px 3px;
}
.btn_print {
    background-color: #1188E8;
    width: 123px;
    height: 33px;
    font-size: 16px;
    border: none;
    color: #ffffff;
    cursor: pointer;
}
.btn_csv {
    background-color: rgb(17, 136, 232);
    width: 123px;
    height: 33px;
    font-size: 16px;
    border: none;
    color: rgb(255, 255, 255);
    cursor: pointer;
    margin-left: 10px;
    text-decoration: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn_print:hover, .btn_csv:hover {
    background-color: #18ecfd;
}
.grp_print_wrap {
    padding: 20px 30px;
    justify-content: center;
    align-items: center;
    display: flex;
}
