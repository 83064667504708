.csvReader{
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  margin-top: 10px;
  justify-content: center;
}

.progressBar{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.browseFile {
  width: 120px;
  font-size: 12px;
  height: 40px;
  background-color: #1188E8;
  border: none;
  color: #ffffff;
  cursor: pointer;
}

.browseFile:hover{
  background-color: #4ea9f3;
}
.acceptedFile {
  border: 1px solid #ccc;
  height: 38px;
  line-height: 38px;
  padding-left: 10px;
  width: 70%;
}

.import {
  border-radius: 0;
  padding: 0px 0px;
  height: 40px;
  width: 120px;
  font-size: 12px;
  background-color: #f5851c;
  border: none;
  color: #ffffff;
  cursor: pointer;
  margin-left: 30px;
}

.import:hover{
  background-color: #f79d4a;
}
.import:disabled{
  background-color: #c18b59;
  color: #797070;
}

.stock_delete {
  border-radius: 0;
  padding: 0px 0px;
  height: 40px;
  width: 120px;
  font-size: 12px;
  background-color: #db1515;
  border: none;
  color: #ffffff;
  cursor: pointer;
  margin-left: 30px;
}

.stock_delete:hover{
  background-color: #e95b5b;
}
