
/* --------------------- page style ----------------- */


/* ===================== loading spiner =================== */
.loader_container {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #00000075;
    align-items: center;
    justify-content: center;
    z-index: 1;
}
.loader_container span {
    border-color: #1188E8 #1188E8 transparent !important;
    border-width: 6px !important;
}