
.hyfiled {
    display: flex;
}
.button {
    background-color: #1188E8;
    width: 165px;
    height: 48px;
    font-size: 16px;
    border-radius: 40px;
    border: none;
    color: #ffffff;
    /* margin-left: 345px; */
    cursor: pointer;
}
.button:hover {
    background-color: #18ecfd;
}
.cs_input {
    border: solid 1px #D6D6D6;
    width: 100%;
    height: 30px;
    margin: 14px 0;
    padding: 0px 10px;
    color: #565656;
    font-size: 16px;
    outline:0px;
}
.cs_label {
    font-size: 14px;
    color: #333333;
}
.cs_blue_label {
    font-size: 14px;
    color: #3C8BDC;
}
.company_wrap {
    margin-right: 20px;
    margin-bottom: 10px;
    width: 35%;
}
.distination_zip_wrap {
    margin-right: 20px;
    margin-bottom: 10px;
    width: 20%;
}
.distination_zip_btn_wrap {
    margin-left: 30px;
}
.distination_zip_btn {
    margin-top: 34px;
    width: 68px;
    height: 32px;
    background-color: #1188E8;
    color: #ffffff;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
    font-size: 14px;
    cursor: pointer;
}
.distination_zip_btn:hover {
    background-color: #18ecfd;
}
.distination_address_wrap {
    margin-right: 40px;
    margin-bottom: 10px;
}
.cs_min_span {
    margin: 0px 5px 9px 5px;
    font-size: 35px;
    line-height: 0px;
}
.cs_ml_100 {
    margin-left:100px;
}
.mt_30 {
    margin-top:30px;
}
.mt_12 {
    margin-top:12px;
}
.mr_18 {
    margin-right:18px;
}
.cs_span_1 {
    color: #AD3232;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: textfield;
}
.buttonBox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
}
.justify_content_e {
    justify-content: end;
}
/* ===================== Modal cont ==========================*/
.search_btn {
    width: 150px;
    height: 30px;
    margin-left: 10px;
    background-color: #1188E8;
    color: #ffffff;
    border: none;
    cursor: pointer;
}
.cs_input_check {
    border: solid 1px #D6D6D6;
    width: 16px;
    height: 30px;
    margin: 14px;
    color: #565656;
    font-size: 16px;
    outline: 0px;
    cursor: pointer;
}
.tableHeader {
    background-color: #DEDBDB;
    border: solid 1px #767676;
    font-weight: 100;
    font-size: 14px;
    height: 30px;
    color: #333333;
    width: 1%;
}
.table {
    width:100%;
    margin-bottom: 23px;
    border-collapse: collapse;
    display: block;
    overflow: auto;
    max-height: 400px;
}
.tableTd {
    border: solid 1px #D6D6D6;
    padding: 5px 10px;
    font-size: 16px;
    font-weight: initial;
    color: #303030;
}
.cs_tbl {
    width:100%;
    margin-bottom: 23px;
    border-collapse: collapse;
    display: block;
    overflow: auto;
    max-height: 400px;
}
.cs_tr {
    display: flex;
}
.cs_tbody .cs_tr{
    cursor: pointer;
}
.cs_th {
    border: solid 1px #767676;
    border-right: none;
    padding: 5px 10px;
    text-align: center;
    background-color: #cfcfcf;

    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.cs_th:last-child {
    border-right: solid 1px #767676;
}
.cs_td {
    border-left: solid 1px #767676;
    border-bottom: solid 1px #767676;
    padding: 5px 10px;
    text-align: center;

    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.cs_td:last-child {
    border-right: solid 1px #767676;
}
.cs_col_md_1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
}
.cs_col_md_2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666666%;
    flex: 0 0 16.666666%;
    max-width: 16.666666%;
}
.cs_col_md_3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}
.cs_col_md_4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

.cs_tbl_input {
    border: solid 1px #D6D6D6;
    width: calc(100% - 15px);
    margin: 0px;
    height: 30px;
    padding: 0px 5px;
    color: #565656;
    font-size: 16px;
    outline: 0px;
}
.cs_tbl_check {
    border: solid 1px #D6D6D6;
    width: 16px;
    color: #565656;
    font-size: 16px;
    outline: 0px;
    cursor: pointer;
    margin: 9px 0px 0px 0px;
}










