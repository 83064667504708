.input {
/* width: 100%; */
width: 140px;
height: 30px;
border: none;
text-align: center;
}
.td {
    border: solid 1px #D6D6D6;
    margin: 0;
    padding: 0;
}