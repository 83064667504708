.wrapper {
    display: flex;
    height: 100%;
    background-color: #F1F1F1;
    min-width: 1510px;
}

.main_wrapper_spread {
    width: 100%;
    overflow-y: auto;
}

.main_wrapper_shrink {
    width: calc(100% - 220px);
}

.header_wrapper {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 270px;
    width: calc(100% - 220px);
    height: 59px;
    background-color: white;
    display:flex;
    justify-content: space-between;
}

.header_padding {
    top: 0;
    left: 220px;
    width: 100%;
    height: 59px;
    background-color: white;
}

.sidebar_wrapper_close {
    z-index: 1;
    /* position: fixed; */
    top: 0;
    left: 0;
    width: 55px;
    height: 100%;
    background-color: #313239;
    transform: translateX(0px);
    transition: all 0.3s;
}

.sidebar_wrapper_open {
    z-index: 3;
    position: fixed;
    top: 0;
    left: 0;
    width: 220px;
    height: 100%;
    background-color: #313239;
    /* transform: none; */
    transition: all 0.3s;
}
.ant-menu::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .ant-menu {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
.sidebar_padding_open {
    width: 220px;
    height: 100%;
    background-color: #F1F1F1;
}

.sidebar_padding_close {
    width: 220px;
    height: 100%;
    transform: translateX(-220px);
    background-color: #F1F1F1;
    display: none;
}


.outlet_wrapper {
    /* min-height: calc(100% - 59px); */
    background-color: #F1F1F1;
    width: 100%;
    /* transform: translateX(190px); */
    transition: all 0.3s;
}

.outlet_wrapper_moved {
    /* min-height: calc(100% - 59px); */
    background-color: #F1F1F1;
    /* width: 120%; */
    /* transform: translateX(-100px); */
    /* transform: scale(1.2); */
    transform-origin: center top;
    transition: all 0.3s;
}