/*====================================================================================================
	作成日:2022/08/25		最終更新日:2022/08/26
	作成者:GFTD天神-徳渕	最終更新者:GFTD天神-徳渕
====================================================================================================*/

.text {
    /* UI Properties */
    border: 1px solid #D6D6D6;
    opacity: 1;
}
