.background {
    background-image: url("../../components/login/background.jpg");
    background-size: cover;

    height: 100%;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #F1F1F1;
}

.registerBackground {
    background-image: url("../../components/login/background.jpg");
    background-size: cover;

    /* height: 100%; */
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #F1F1F1;
    display: flex;
}

.form { 
    /* Layout Properties */
    top: 206px;
    left: 393px;
    width: 494px;
    height: 389px;
    /* UI Properties */
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

.endForm {
    width: 494px;
    height: 200px;
    /* UI Properties */
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

.inputForm {
    width: 630px;
    /* height: 850px; */
    /* UI Properties */
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;

    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.inputSubForm {
    width: 900px;
    /* UI Properties */
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;

    margin: auto;
}

.title {
    /* UI Properties */
    font: var(--unnamed-font-style-normal) normal bold 20px/23px var(--unnamed-font-family--applesystemuifont);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-333333);
    text-align: left;
    font: normal normal bold 20px/23px AppleSystemUIFont;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;

    padding-top: 24px;
    padding-left: 26px;
}

.subTitle {
    /* UI Properties */
    font: var(--unnamed-font-style-normal) normal bold 20px/23px var(--unnamed-font-family--applesystemuifont);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-333333);
    text-align: left;
    font: normal normal bold 10px/23px AppleSystemUIFont;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;

    padding-top: 24px;
    padding-left: 26px;

}

.label {
    /* UI Properties */
    font: var(--unnamed-font-style-normal) normal bold 20px/23px var(--unnamed-font-family--applesystemuifont);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-333333);
    text-align: left;
    font: normal normal bold 10px/23px AppleSystemUIFont;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
}

.link {
    font: var(--unnamed-font-style-normal) normal bold 20px/23px var(--unnamed-font-family--applesystemuifont);
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
    font: normal normal bold 10px/23px AppleSystemUIFont;
    letter-spacing: 0px;
    opacity: 1;
}

.emailText {
    border: none;
    width: 420px;
    margin-left: 10px;
}

.emailField {
    border: 1px solid #EEE;
    box-shadow: rgba(122, 122, 122, 0.0588235) 0px 0px 6px 3px;
    -webkit-box-shadow: rgba(122, 122, 122, 0.0588235) 0px 0px 6px 3px;
    -moz-box-shadow: rgba(122, 122, 122, 0.0588235) 0px 0px 6px 3px;
    border-radius: 5px;
    width: 440px;
    margin-left: 26px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-wrap: nowrap;
}

.PasswordText {
    border: none;
    width: 400px;
    margin-left: 10px;
}

.passwordField {
    border: 1px solid #EEE;
    box-shadow: rgba(122, 122, 122, 0.0588235) 0px 0px 6px 3px;
    -webkit-box-shadow: rgba(122, 122, 122, 0.0588235) 0px 0px 6px 3px;
    -moz-box-shadow: rgba(122, 122, 122, 0.0588235) 0px 0px 6px 3px;
    border-radius: 5px;
    width: 440px;
    margin-left: 26px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-wrap: nowrap;
}

.PasswordReveal {
    right: 10px;
    top: 10px;
    border: none;
}

.checkboxField {
    padding-top: 24px;
    padding-left: 26px;
    display: flex;
    flex-wrap: nowrap;
}

.linkField {
    padding-top: 24px;
    text-align: center;
}

.pipeLin {
    margin-left: 20px;
    margin-right: 20px;
}

.submitButtonField {
    padding-top: 24px;
    text-align: center;
}

.submitButton {
    width: 100px;
    height: 25px;

    /* UI Properties */
    background: #1188E8 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    border: none;

    font: var(--unnamed-font-style-normal) normal bold 20px/23px var(--unnamed-font-family--applesystemuifont);
    letter-spacing: var(--unnamed-character-spacing-0);
    font: normal normal bold 10px/23px AppleSystemUIFont;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;

    text-align: center;
}

input:-webkit-autofill {
    box-shadow: 0 0 0 1000px white inset;
}

input[type="text"]:focus {
    outline: none;
}

input[type="password"]:focus {
    outline: none;
}

.userRegistryLayout {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: 600px;
    width: 494px;
    justify-content: center;
    align-items: end;
}

.userRegistryBut {
    padding: 0px 20px;
    background-color: #1188E8;
    border-radius: 10px;
    font-size: 15px;
    text-decoration: unset;
    color: white;
}

.userRegistryBut:hover {
    background-color: #2e8edd;
}

.buttonLayout {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 65px;
}

.linkLayout {
    display: flex;
    justify-content: center;
    gap: 35px;
    margin-top: 30px;
}

.warehouseRegistryBut {
    padding: 10px 80px;
    background-color: #1188E8;
    font-size: 15px;
    text-decoration: unset;
    color: white;
    border-color: #1188e8;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
}

/* .warehouseRegistryBut:hover {
    background-color: white;
    color: #1188E8;
} */

.shipperRegistryBut {
    padding: 10px 80px;
    background-color: white;
    font-size: 15px;
    text-decoration: unset;
    color: #1188E8;
    border-color: #1188e8;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
}

/* .shipperRegistryBut:hover {
    background-color: #2e8edd;
    color: white;
} */

.commandLayout {
    display: flex;
    justify-content: center;
    margin-top: 55px;
}
.endLayout{
    display: flex;
    justify-content: center;
    margin-top: 55px;
    flex-direction: column;
    align-items: center;
}
.registryLink {
    font: var(--unnamed-font-style-normal) normal bold 20px/23px var(--unnamed-font-family--applesystemuifont);
    letter-spacing: 1px;
    font: normal normal bold 10px/23px AppleSystemUIFont;
    opacity: 1;
    padding: 10px 40px;
    font-size: 12px;
}

.cs_input{
    border: solid 1px #D6D6D6;
    width: 90%;
    height: 30px;
    padding: 0px 10px;
    color: #565656;
    font-size: 14px;
    outline: 0px;
}
.inputLayout{
    margin-left: 40px;
    margin-top: 30px;
}

.inputLabel{
    font-size: 14px;
    margin-bottom: 5px;
}

.subLayout{
    margin-top: 10px;
}

.zipGetBut {
    padding: 3px 20px;
    background-color: #1188E8;
    font-size: 13px;
    text-decoration: unset;
    color: white;
    border-color: #1188e8;
    border-style: solid;
    border-radius: 15px;
    margin-left: 10px;
}

.registryBut {
    padding: 7px 50px;
    background-color: #1188E8;
    font-size: 13px;
    text-decoration: unset;
    color: white;
    border-color: #1188e8;
    border-style: solid;
    border-radius: 25px;
    margin-bottom: 20px;
}

.PasswordText{
    border: none;
    width: 515px;
    margin-left: 10px;
}

.passwordField{
    border:1px solid #D6D6D6;
    width: 550px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-wrap: nowrap;
    border-radius: 0px;
    margin-left: 0px;
}

.PasswordReveal {
    right: 10px;
    top: 10px;
    border: none;
}
