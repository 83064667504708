/*====================================================================================================
	作成日:2022/08/25		最終更新日:2022/08/26
	作成者:GFTD天神-徳渕	最終更新者:GFTD天神-徳渕
====================================================================================================*/

.label {
    /* UI Properties */
    background: #DEDBDB 0% 0% no-repeat padding-box;
    border: 1px solid #D6D6D6;
    opacity: 1;

    text-align: center;
    font-weight: normal;
}